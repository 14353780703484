.accordion-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f9f9f9;
  padding: 10px;
  cursor: pointer;
}

.accordion-header h5 {
  margin: 0;
}

.accordion-content {
  padding: 10px;
}

.accordion-content li {
  list-style-type: none;
  margin-bottom: 10px;
}

.accordion-content button {
  display: block;
  margin-top: 30px;
  margin-bottom: 0.8rem;
}

.accordion-content button.btn-success {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.accordion-content button.btn-success:hover {
  background-color: #218838;
}
/* .card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  background-color: #fff;
} */

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 8px;
}

.card-description {
  font-size: 16px;
  color: #555;
}
